<template>
  <div class="players-container">
    <v-select
      class="ml-2"
      clearable
      :items="rounds"
      filled
      hide-details
      label="Rounds"
      return-object
      v-model="selectedRound"
      @change="updateTableData"
      item-text="name"
    ></v-select>
    <v-text-field
      class="my-4 search-name"
      append-icon="mdi-magnify"
      label="Search Name..."
      single-line
      hide-details
      clearable
      v-model="search"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :items="fetchedPlayers"
      item-key="id"
      mobile-breakpoint="0"
      :loading="loading"
      loading-text="Loading players..."
      :search="search"
    >
      <template v-slot:loading>
        <h2>Please select a round!</h2>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Home",

  data() {
    return {
      rounds: [
        {
          name: "1 - from: 2022-8-2, to: 2022-8-8",
          from: "2022-8-2",
          to: "2022-8-8",
        },
        {
          name: "2 - from: 2022-8-9, to: 2022-8-15",
          from: "2022-8-9",
          to: "2022-8-15",
        },
        {
          name: "3 - from: 2022-8-16, to: 2022-8-22",
          from: "2022-8-16",
          to: "2022-8-22",
        },
        {
          name: "4 - from: 2022-8-23, to: 2022-8-29",
          from: "2022-8-23",
          to: "2022-8-29",
        },
        {
          name: "5 - from: 2022-8-30, to: 2022-9-5",
          from: "2022-8-30",
          to: "2022-9-5",
        },
        {
          name: "6 - from: 2022-9-6, to: 2022-9-12",
          from: "2022-9-6",
          to: "2022-9-12",
        },
        {
          name: "7 - from: 2022-9-13, to: 2022-9-19",
          from: "2022-9-13",
          to: "2022-9-19",
        },
        {
          name: "8 - from: 2022-9-20, to: 2022-9-26",
          from: "2022-9-20",
          to: "2022-9-26",
        },
        {
          name: "9 - from: 2022-9-27, to: 2022-10-3",
          from: "2022-9-27",
          to: "2022-10-3",
        },
        {
          name: "10 - from: 2022-10-4, to: 2022-10-10",
          from: "2022-10-4",
          to: "2022-10-10",
        },
        {
          name: "11 - from: 2022-10-11, to: 2022-10-17",
          from: "2022-10-11",
          to: "2022-10-17",
        },
        {
          name: "12 - from: 2022-10-18, to: 2022-10-24",
          from: "2022-10-18",
          to: "2022-10-24",
        },
        {
          name: "13 - from: 2022-10-25, to: 2022-10-31",
          from: "2022-10-25",
          to: "2022-10-31",
        },
        {
          name: "14 - from: 2022-11-1, to: 2022-11-7",
          from: "2022-11-1",
          to: "2022-11-7",
        },
        {
          name: "15 - from: 2022-11-8, to: 2022-11-14",
          from: "2022-11-8",
          to: "2022-11-14",
        },
        {
          name: "16 - from: 2022-11-15, to: 2022-11-21",
          from: "2022-11-15",
          to: "2022-11-21",
        },
        {
          name: "17 - from: 2022-11-22, to: 2022-11-28",
          from: "2022-11-22",
          to: "2022-11-28",
        },
        {
          name: "18 - from: 2022-11-29, to: 2022-12-5",
          from: "2022-11-29",
          to: "2022-12-5",
        },
        {
          name: "19 - from: 2022-12-6, to: 2022-12-12",
          from: "2022-12-6",
          to: "2022-12-12",
        },
        {
          name: "20 - from: 2022-12-13, to: 2022-12-19",
          from: "2022-12-13",
          to: "2022-12-19",
        },
        {
          name: "21 - from: 2022-12-20, to: 2022-12-26",
          from: "2022-12-20",
          to: "2022-12-26",
        },
        {
          name: "22 - from: 2022-12-27, to: 2023-1-2",
          from: "2022-12-27",
          to: "2023-1-2",
        },
        {
          name: "23 - from: 2023-1-3, to: 2023-1-9",
          from: "2023-1-3",
          to: "2023-1-9",
        },
        {
          name: "24 - from: 2023-1-10, to: 2023-1-16",
          from: "2023-1-10",
          to: "2023-1-16",
        },
        {
          name: "25 - from: 2023-1-17, to: 2023-1-23",
          from: "2023-1-17",
          to: "2023-1-23",
        },
        {
          name: "26 - from: 2023-1-24, to: 2023-1-30",
          from: "2023-1-24",
          to: "2023-1-30",
        },
        {
          name: "27 - from: 2023-1-31, to: 2023-2-6",
          from: "2023-1-31",
          to: "2023-2-6",
        },
        {
          name: "28 - from: 2023-2-7, to: 2023-2-13",
          from: "2023-2-7",
          to: "2023-2-13",
        },
        {
          name: "29 - from: 2023-2-14, to: 2023-2-20",
          from: "2023-2-14",
          to: "2023-2-20",
        },
        {
          name: "30 - from: 2023-2-21, to: 2023-2-27",
          from: "2023-2-21",
          to: "2023-2-27",
        },
        {
          name: "31 - from: 2023-2-28, to: 2023-3-6",
          from: "2023-2-28",
          to: "2023-3-6",
        },
        {
          name: "32 - from: 2023-3-7, to: 2023-3-13",
          from: "2023-3-7",
          to: "2023-3-13",
        },
        {
          name: "33 - from: 2023-3-14, to: 2023-3-20",
          from: "2023-3-14",
          to: "2023-3-20",
        },
        {
          name: "34 - from: 2023-3-21, to: 2023-3-27",
          from: "2023-3-21",
          to: "2023-3-27",
        },
        {
          name: "35 - from: 2023-3-28, to: 2023-4-3",
          from: "2023-3-28",
          to: "2023-4-3",
        },
        {
          name: "36 - from: 2023-4-4, to: 2023-4-10",
          from: "2023-4-4",
          to: "2023-4-10",
        },
        {
          name: "37 - from: 2023-4-11, to: 2023-4-17",
          from: "2023-4-11",
          to: "2023-4-17",
        },
        {
          name: "38 - from: 2023-4-18, to: 2023-4-24",
          from: "2023-4-18",
          to: "2023-4-24",
        },
        {
          name: "39 - from: 2023-4-25, to: 2023-5-1",
          from: "2023-4-25",
          to: "2023-5-1",
        },
        {
          name: "40 - from: 2023-5-2, to: 2023-5-8",
          from: "2023-5-2",
          to: "2023-5-8",
        },
        {
          name: "41 - from: 2023-5-9, to: 2023-5-15",
          from: "2023-5-9",
          to: "2023-5-15",
        },
        {
          name: "42 - from: 2023-5-16, to: 2023-5-22",
          from: "2023-5-16",
          to: "2023-5-22",
        },
        {
          name: "43 - from: 2023-5-23, to: 2023-5-29",
          from: "2023-5-23",
          to: "2023-5-29",
        },
        {
          name: "44 - from: 2023-5-30, to: 2023-6-5",
          from: "2023-5-30",
          to: "2023-6-5",
        },
      ],
      selectedRound: null,
      fetchLoading: false,
      search: "",
      fetchedPlayers: [],
      positions: ["GK", "DEF", "MID", "ST"],
      itemsPerPageOptions: [25, 50, 100],
      headers: [
        { text: "Name", value: "name", sortable: false, width: "25%" },
        { text: "Team", value: "team", sortable: false, width: "20%" },
        { text: "League", value: "league", sortable: false, width: "15%" },
        { text: "GK", value: "GK", sortable: true, width: "10%" },
        { text: "DEF", value: "DEF", sortable: true, width: "10%" },
        { text: "MID", value: "MID", sortable: true, width: "10%" },
        { text: "ST", value: "ST", sortable: true, width: "10%" },
      ],
      leaguesMap: {
        2: "England-Premier-League",
        22: "France-Ligue-1",
        3: "Germany-Bundesliga",
        5: "Italy-Serie-A",
        13: "Netherlands-Eredivisie",
        21: "Portugal-Liga-NOS",
        4: "Spain-LaLiga",
        17: "Turkey-Super-Lig",
      },
      pointsMap: {
        GK: {
          assists: 5,
          cleanSheet: 5,
          clearanceOffLine: 2,
          errorLeadToGoal: -1,
          goals: 10,
          lastManTackle: 2,
          manOfTheMatch: 3,
          ownGoals: -3,
          penaltyGoals: 4,
          penaltyMissed: -3,
          penaltySaved: 3,
          bonusRating: 1,
          redCards: -3,
          saves: 1,
          shotsOnPost: 3,
          starter: 2,
          sub: 1,
          teamVictory: 1,
          threeAllowed: -2,
          yellowCards: -1,
        },
        DEF: {
          assists: 3,
          cleanSheet: 4,
          clearanceOffLine: 2,
          errorLeadToGoal: -1,
          goals: 7,
          lastManTackle: 2,
          manOfTheMatch: 3,
          ownGoals: -3,
          penaltyGoals: 4,
          penaltyMissed: -3,
          penaltySaved: 0,
          bonusRating: 1,
          redCards: -3,
          saves: 0,
          shotsOnPost: 1,
          starter: 2,
          sub: 1,
          teamVictory: 1,
          threeAllowed: -1,
          yellowCards: -1,
        },
        MID: {
          assists: 3,
          cleanSheet: 1,
          clearanceOffLine: 2,
          errorLeadToGoal: -1,
          goals: 6,
          lastManTackle: 2,
          manOfTheMatch: 3,
          ownGoals: -3,
          penaltyGoals: 4,
          penaltyMissed: -3,
          penaltySaved: 0,
          bonusRating: 1,
          redCards: -3,
          saves: 0,
          shotsOnPost: 1,
          starter: 2,
          sub: 1,
          teamVictory: 1,
          threeAllowed: 0,
          yellowCards: -1,
        },
        ST: {
          assists: 3,
          cleanSheet: 0,
          clearanceOffLine: 2,
          errorLeadToGoal: -1,
          goals: 5,
          lastManTackle: 2,
          manOfTheMatch: 3,
          ownGoals: -3,
          penaltyGoals: 4,
          penaltyMissed: -3,
          penaltySaved: 0,
          bonusRating: 1,
          redCards: -3,
          saves: 0,
          shotsOnPost: 1,
          starter: 2,
          sub: 1,
          teamVictory: 1,
          threeAllowed: 0,
          yellowCards: -1,
        },
      },
    };
  },
  computed: {
    loading() {
      return (
        !this.fetchedPlayers.length || this.fetchLoading || !this.selectedRound
      );
    },
    fetchUrl() {
      return `https://scraper.ff-legends.com/results?league=&from=${this.selectedRound.from}&till=${this.selectedRound.to}&format=json&target=`;
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),

    pointsCalculator(stats) {
      return this.positions.reduce((result, position) => {
        const total = Object.keys(stats).reduce((sum, stat) => {
          if (!this.pointsMap[position][stat]) {
            return sum;
          }

          const playerStat = stats[stat];

          const statPoints = +playerStat * +this.pointsMap[position][stat];
          return sum + statPoints;
        }, 0);

        return { ...result, [position]: total };
      }, {});
    },

    transformData(data = []) {
      let result = [];
      for (const league in data) {
        const leagueData = data[league];
        const leagueName = this.leaguesMap[leagueData.id];

        for (const team in leagueData.teams) {
          const teamData = leagueData.teams[team];
          const teamName = teamData.name;
          for (const player in teamData.players) {
            const playerData = {
              ...teamData.players[player],
              team: teamName,
              league: leagueName,
              ...this.pointsCalculator(teamData.players[player]),
            };
            result.push(playerData);
          }
        }
      }
      return result;
    },

    async updateTableData() {
      try {
        this.fetchLoading = true;
        const result = await axios.get(this.fetchUrl);
        this.fetchLoading = false;
        this.fetchedPlayers = this.transformData(result?.data);
      } catch (error) {
        this.fetchedPlayers = [];
        this.fetchLoading = false;
      }
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
@import "../common/breakpoints.scss";

.players-container {
  overflow-x: auto;
  padding: 20px 20px 40px 20px;

  @media #{$mobile} {
    padding-bottom: 100px;
  }
  @media #{$tablet} {
    padding-bottom: 100px;
  }
}

.search-name {
  background-color: white;
}
</style>
